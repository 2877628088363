;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"d813f6e95195f63b4855e35bb5d52c8706975a0d"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "/hcp";import * as Sentry from '@sentry/nextjs';
import type { BrowserOptions } from '@sentry/nextjs';
import type { Client } from '@sentry/types';

import sharedSentryOptions from './shared-sentry-options';

export const initSentry = (
    additionalOptions: BrowserOptions
): Client | undefined =>
    Sentry.init({
        ...sharedSentryOptions,
        ...additionalOptions,
    });
